import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid';

$(() => {
  const $app = $('#app')

  const deleteActionThen = (reloadUrl, eventName) => {
    if (eventName) {
      document.dispatchEvent(new Event(eventName))
      return
    }

    if (reloadUrl) {
      window.location.href = reloadUrl
      return
    }

    location.reload()
  }

  const deleteForceActionCatch = (error, $btn) => {
    const code = uuidv4()
    let confirmationCode = code.replace(/-/g, '').substr(0, 8);

    const url = $btn.getAttribute('data-url')
    const reloadUrl = $btn.getAttribute('data-reload-url')
    const eventName = $btn.getAttribute('data-event-name')

    Swal.fire({
      title: window.trans.trans('remove_model.delete_force_title'),
      text: window.trans.trans('remove_model.delete_force_text', { code: confirmationCode }),
      confirmButtonText: window.trans.trans('remove_model.confirm'),
      cancelButtonText: window.trans.trans('remove_model.cancel'),
      type: 'warning',
      showCancelButton: true,
      input: "text",
      backdrop: false,
      allowOutsideClick: false,
      preConfirm: async (code) => {
        if (code === confirmationCode)  {
          return code
        } else {
          Swal.showValidationMessage( window.trans.trans('remove_model.code_error_message'));
        }
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        deleteForceAction(url, reloadUrl, eventName)
      }
    })
  }

  const deleteActionCatch = (error) => {
    const exceptionCode = error.response.data.exception.code
    let exception = error.response.data.exception
    let translationString = 'remove_model.error_text'

    if (exceptionCode !== undefined) {
      translationString = 'remove_model.error_text_' + exceptionCode
      if (window.trans.trans(translationString) === translationString) {
        translationString = 'remove_model.error_text'
      }
    }
    if (exception.hasOwnProperty('message')) {
      translationString = exception.message
    }

    if (translationString.includes('1451')) {
      translationString = 'remove_model.error_text_1451'
    }

    Swal.fire(
      window.trans.trans('remove_model.error_title'),
      window.trans.trans(translationString),
      'error',
    )
  }

  const deleteForceAction = (url, reloadUrl, eventName) => {
    let urlForce = url + '/destroyForce'
    axios.delete(urlForce).then(() => {
      Swal.fire({
        type: 'success',
        title: window.trans.trans('remove_model.success_title'),
        text: window.trans.trans('remove_model.success_text'),
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        deleteActionThen(reloadUrl, eventName)
      })
    }).catch((error) => {
      deleteActionCatch(error)
    })
  }

  const deleteAction = ($btn) => {
    const url = $btn.getAttribute('data-url')
    const reloadUrl = $btn.getAttribute('data-reload-url')
    const eventName = $btn.getAttribute('data-event-name')
    const permissionForce = $btn.getAttribute('force_delete')
    axios.delete(url).then(() => {
      Swal.fire({
        type: 'success',
        title: window.trans.trans('remove_model.success_title'),
        text: window.trans.trans('remove_model.success_text'),
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        deleteActionThen(reloadUrl, eventName)
      })
    }).catch((error) => {
      if (permissionForce === "true") {
        deleteForceActionCatch(error, $btn)
      } else {
        deleteActionCatch(error)
      }
    })
  }

  $app.on('click', '.js-remove-model', (el) => {
    const $btn = el.currentTarget

    Swal.fire({
      title: window.trans.trans('remove_model.title'),
      text: window.trans.trans('remove_model.text'),
      confirmButtonText: window.trans.trans('remove_model.confirm'),
      cancelButtonText: window.trans.trans('remove_model.cancel'),
      type: 'warning',
      showCancelButton: true,
    }).then(async (result) => {
      if (result.value === true) {
        deleteAction($btn)
      }
    })
  })


  const cloneActionCatch = (error) => {
    const exceptionCode = error.response.data.exception.code
    let exception = error.response.data.exception
    let translationString = 'clone_model.error_text'

    if (exceptionCode !== undefined) {
      translationString = 'clone_model.error_text_' + exceptionCode
      if (window.trans.trans(translationString) === translationString) {
        translationString = 'clone_model.error_text'
      }
    }
    if (exception.hasOwnProperty('message')) {
      translationString = exception.message
    }

    if (translationString.includes('1451')) {
      translationString = 'clone_model.error_text_1451'
    }

    Swal.fire(
      window.trans.trans('clone_model.error_title'),
      window.trans.trans(translationString),
      'error',
    )
  }

  const cloneAction = (url, reloadUrl, eventName, selectedOptions) => {
    axios.post(url, {options: selectedOptions}).then(() => {
      Swal.fire({
        type: 'success',
        title: window.trans.trans('clone_model.success_title'),
        text: window.trans.trans('clone_model.success_text'),
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        if (eventName) {
          document.dispatchEvent(new Event(eventName))
          return
        }

        if (reloadUrl) {
          window.location.href = reloadUrl
          return
        }

        location.reload()
      })
    }).catch((error) => {
      cloneActionCatch(error)
    })
  }

  $app.on('click', '.js-clone-model', (el) => {
    const $btn = el.currentTarget
    const url = $btn.getAttribute('data-url')
    const reloadUrl = $btn.getAttribute('data-reload-url')
    const eventName = $btn.getAttribute('data-event-name')
    const optionsData = $btn.getAttribute('data-options')
    const options = JSON.parse(optionsData)

    const optionsContainer = document.createElement('div');
    optionsContainer.id = 'cloneOptions'
    optionsContainer.innerHTML = '<component :is="componentLoader"></component>'
    let realHtml = ''

    if (options && options.component) {
      Swal.fire({
        title: window.trans.trans('clone_model.title'),
        text: window.trans.trans('clone_model.text'),
        html: optionsContainer,
        confirmButtonText: window.trans.trans('clone_model.confirm'),
        cancelButtonText: window.trans.trans('clone_model.cancel'),
        type: 'warning',
        showCancelButton: true,
        didRender: (dom) => {
          realHtml = dom
          new Vue({
            el: '#cloneOptions',
            data: {
              component: options.component
            },
            computed: {
              componentLoader () {
                return () => import(`@/js/components/clone_options/${this.component}.vue`)
              }
          },
          })
        }
      }).then(async (result) => {
        if (result.value === true) {
          const hiddenInput = realHtml.querySelectorAll('input[type="hidden"]').item(0)
          const selectedOptions = hiddenInput ? JSON.parse(hiddenInput.value) : {}

          cloneAction(url, reloadUrl, eventName, selectedOptions)
        }
      })
    } else {
      cloneAction(url, reloadUrl, eventName, {})
    }
  })

  $app.on('click', '.js-print', (el) => {
    const $btn = el.currentTarget
    const url = $btn.getAttribute('data-url')

    const config = {
      responseType: 'arraybuffer',
    }
    axios.post(url, {}, config).then(response => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        window.open(window.URL.createObjectURL(blob), '_blank')
    }).catch(() => {
      Swal.fire(
        window.trans.trans('print_accreditation.error_title'),
        window.trans.trans('print_accreditation.error_text'),
        'error',
      )
    })
  })

  const buttonsModel = [
    {
      class: '.js-duplicate-model',
      translation: 'duplicate_model',
    },
    {
      class: '.js-active-model',
      translation: 'active_model',
    },
    {
      class: '.js-stop-model',
      translation: 'stop_model',
    },
  ]
  const classToClick = buttonsModel.map(buttonModel => buttonModel.class)
  const translation = (classClicked) => {
    return buttonsModel.find(buttonModel => buttonModel.class === ('.' + classClicked))
  }

  $app.on('click', _.join(classToClick), (el) => {
    const $btn = $(el.currentTarget)
    const url = $btn.data('url')

    let classClicked = Array.from($btn[0].classList).find(item => classToClick.includes('.' + item))
    const modal = translation(classClicked)

    Swal.fire({
      animation: false,
      title: window.trans.trans(modal.translation + '.title'),
      text: window.trans.trans(modal.translation + '.text'),
      confirmButtonText: window.trans.trans(modal.translation + '.confirm'),
      cancelButtonText: window.trans.trans(modal.translation + '.cancel'),
      type: 'warning',
      showCancelButton: true,
    }).then((result) => {
      if (result.value === true) {
        window.location.href = url
      }
    })
  })

  $app.on('click', '.js-save-and-new', (el) => {
    el.preventDefault()
    el.stopPropagation()

    const $submitButton = $('.js-submit-form')
    const $form = $(el.currentTarget).closest('form')

    $form.append('<input name="save_and_new" type="hidden" value="1" />')
    $submitButton.trigger('click')
  })

  $app.on('click', '.js-save-and-close', (el) => {
    el.preventDefault()
    el.stopPropagation()

    const $submitButton = $('.js-submit-form')
    const $form = $(el.currentTarget).closest('form')

    $form.append('<input name="save_and_close" type="hidden" value="1" />')
    $submitButton.trigger('click')
  })

  $('.js-toolbar-edit').replaceWith(function () {
    return $(`<a class="${$(this)[0].classList.value}" href="${$(this).data('url')}">${$(this).html()}</a>`)
  })

  $('.js-unlock-and-edit').on('click', (el) => {
    el.preventDefault()
    el.stopPropagation()

    const elem = $(el.currentTarget)
    $.get(elem.attr('href'), () => {
      $('.js-toolbar-edit')[0].click()
    })
  })

  $app.on('click', '.js-treeview-model', function () {
    const ANGLE_UP = 'fa-angle-up'
    let $this = $(this)
    let $parentTR = $this.closest('tr')

    let icon = $this.find('i')

    if (icon.hasClass(ANGLE_UP)) {
      icon.removeClass(ANGLE_UP).addClass('fa-angle-down')
      $parentTR.next().show(400)
    } else {
      icon.removeClass('fa-angle-down').addClass(ANGLE_UP)
      $parentTR.next().hide(400)
    }

    if (!$parentTR.next().hasClass('treeView')) {
      $parentTR.after($(this).data().template)
    }

  })

  function removeEmptyTreeViewColumns () {
    if (!$('.js-treeview-model').length) {
      $('.js-treeview-column').remove()
    }
  }

  function resetForm (event) {
    event.preventDefault()

    const form = event.target
    const elements = form.elements
    const skipElements = ['hidden', 'button', 'reset', 'submit']
    const select2Class = 'js-select2'

    for (let element of elements) {
      if (skipElements.includes(element.type)) {
        continue
      }

      let defaultValue = element.getAttribute('data-default-value')
      if (defaultValue && element.type === 'select-multiple') {
        defaultValue = defaultValue.split(',') // separar valores múltiples

        for (let option of element.options) {
          option.selected = defaultValue.includes(option.value)
        }
      } else if (element.type === 'checkbox' || element.type === 'radio') {
        element.checked = Boolean(defaultValue)
      } else { // Otros inputs (textos, select simple)
        element.value = defaultValue || ''
      }

      // Resetear Select2
      if (element.classList.contains(select2Class)) {
        $(element).val(defaultValue).trigger('change')
      }
    }
  }
  const forms = document.querySelectorAll('form.form-search')
  forms.forEach(form => {
      form.addEventListener('reset', resetForm)
  })

  function fixUrlSlash () {
    const currentUrl = window.location.href
    const lastChar = currentUrl.substr(-1)

    function urlFixer (url) {
      let i = 1
      while (url.substring(0, url.length - i).substr(-1) === '/') {
        i++
      }
      return url.substring(0, url.length - i)
    }

    if (lastChar === '/') {
      const fixedUrl = urlFixer(currentUrl)
      if (history.replaceState) {
        history.replaceState(null, null, fixedUrl)
      } else {
        // Fallback for older browsers where pushState is not available - page will reload
        document.location.href = fixedUrl
      }
    }
  }

  let submiting = false
  $(document).on('click', '.js-submit-form', function (e) {
    if (submiting) {
      e.preventDefault()
      return false
    } else {
      submiting = true
      return true
    }
  })

  // En esta variable tendremos almacenados los items que se vayan seleccionando
  // para luego poder disponer de ellos en cualquier accion
  let itemsSelected = [];

  function controlItemsSelected (_item, massiveSelection) {
    let item = $(_item).get(0);
    let idItem = $(item).closest('tr').attr('data-model-id');

    if (massiveSelection) {
      item.checked = !item.checked;
    }

    if (item.checked) {
      itemsSelected.push(idItem);
    } else {
      _.remove(itemsSelected, (i) => i === idItem);
    }
  }

  if($app.find('.th-selectItems').length) {
    // Massive Selection
    $app.on('click', '.th-selectItems', function () {
      let th = $(this);
      th.closest('#entity-list').find('input.selItem').each(function () {
        controlItemsSelected(this, true);
        _.intersection(itemsSelected);
      });
    });
    // Single Selection
    $app.on('click', 'input.selItem', function () {
      controlItemsSelected(this, false);
      _.intersection(itemsSelected);
    });

    $app.on('click', '.js-custom-control-button', (el) => {
      const $btn = el.currentTarget

      if ($btn.getAttribute('data-check-selected') && !itemsSelected.length) {
        toastr.warning($btn.getAttribute('data-no-items-message'))

        return
      }

      if ($btn.getAttribute('data-show-warning') === 'true') {
        Swal.fire({
          title: $btn.getAttribute('data-warning-title'),
          text: $btn.getAttribute('data-warning-message'),
          confirmButtonText: window.trans.trans('remove_model.confirm'),
          cancelButtonText: window.trans.trans('remove_model.cancel'),
          type: 'warning',
          showCancelButton: true,
        }).then(async (result) => {
          if (result.value === true) {
            executeAction($btn)
          }
        })
      } else {
        executeAction($btn)
      }
    })

    const customJsActionsFunctions = {
      addItems: function (items) {
        window.itemsSelected = items
      }
      /*example: function (items) {
        console.log(items)
      }*/
    }

    const deleteItemsForceActionCatch = (error, $btn) => {
      const code = uuidv4()
      let confirmationCode = code.replace(/-/g, '').substr(0, 8);

      Swal.fire({
        title: window.trans.trans('remove_model.delete_force_title'),
        text: window.trans.trans('remove_model.delete_force_text', { code: confirmationCode }),
        confirmButtonText: window.trans.trans('remove_model.confirm'),
        cancelButtonText: window.trans.trans('remove_model.cancel'),
        type: 'warning',
        showCancelButton: true,
        input: "text"
      }).then(async (result) => {
        if (result.value === confirmationCode) {
          deleteItemsForceAction($btn)
        }
      })
    }


    const deleteItemsForceAction = ($btn) => {
      const url = $btn.getAttribute('data-url')

      axios.post(url, { items: itemsSelected, force: true })
        .then(() => {
          executionOK($btn)
        }).catch((error) => {
          deleteActionCatch(error)
        })
    }

    function executeAction($btn) {

      if ($btn.getAttribute('data-backed-action') === 'true') {
        //llamada post a ruta
        const url = $btn.getAttribute('data-url')
        const permissionForce = $btn.getAttribute('force_delete')

        axios.post(url, { items: itemsSelected })
          .then(() => {
            executionOK($btn)
          }).catch((error) => {
            if (permissionForce === "true") {
              deleteItemsForceActionCatch(error, $btn)
            }else {
              deleteActionCatch(error)
            }
          })
      } else {
        const promise = new Promise((resolve, reject) => {
          resolve(customJsActionsFunctions[$btn.getAttribute('data-js-action')](itemsSelected))
        })
        promise.then(() => {
          executionOK($btn)
        })
      }
    }

    function executionOK($btn) {
      const eventName = $btn.getAttribute('data-event-name')
      const reloadUrl = $btn.getAttribute('data-reload-url')

      dispatchEvent(eventName)
          if(reloadUrl) {
            window.location.href = reloadUrl
          }
    }

    function dispatchEvent(event) {
      if (event && event.length) {
        document.dispatchEvent(new Event(event))
      }
    }
  }

  fixUrlSlash()
  removeEmptyTreeViewColumns()
})

